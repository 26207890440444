const histories = [
  {
    id: 152110,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T10:58:46.9870016',
    pl: -30000,
    totalAmount: 130000,
    totalWin: 100000,
    summary: -113750
  },
  {
    id: 151311,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:00:30.775095',
    pl: -30000,
    totalAmount: 130000,
    totalWin: 100000,
    summary: -83750 }, { id: 15112,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:19:04.2699308',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -53750
  },
  {
    id: 155113,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:19:24.9007836',
    pl: 0,
    totalAmount: 5000,
    totalWin: 5000,
    summary: -51250 }, { id: 15114,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:21:12.2692627',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -51250 },

  {
    id: 1517815,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:23:34.2188503',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -48750
  },
  {
    id: 15164516,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T11:24:56.1845631',
    pl: -10000,
    totalAmount: 30000,
    totalWin: 20000,
    summary: -46250 },
  {
    id: 1512347,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:07:19.8921552',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -36250
  },
  {
    id: 151123153,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:19:46.6959193',
    pl: -2500,
    totalAmount: 2500,
    totalWin: 0,
    summary: -33750
  },
  {
    id: 151543654754,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:21:51.7661881',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -31250
  },
  {
    id: 1511241144453,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:19:46.6959193',
    pl: -2500,
    totalAmount: 2500,
    totalWin: 0,
    summary: -33750
  },
  {
    id: 15164643654,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:21:51.7661881',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -31250
  },
  {
    id: 15123534677753,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:19:46.6959193',
    pl: -2500,
    totalAmount: 2500,
    totalWin: 0,
    summary: -33750
  },
  {
    id: 12412414156,
    userId: 'bf8ec4e2-1587-4490-a291-dea335309822',
    userName: 'datucha',
    createDateUtc: '2021-08-22T12:21:51.7661881',
    pl: -2500,
    totalAmount: 7500,
    totalWin: 5000,
    summary: -31250
  }
];

class HistoryAPI {
  async getHistories(week, skip, take) {
    return Promise.resolve({
      meta: {
        skip,
        take,
        total: histories.length
      },
      entries: histories.slice(skip, skip + take)
    });
  }

  async getHistory(id) {
    return Promise.resolve(histories.find((x) => x.id === id) || {});
  }
}

export const historyAPI = new HistoryAPI();
