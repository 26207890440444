import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

class APOLLO {
  constructor() {
    this.HOST = null;
    this.apolloClient = null;

    this.initialized = false;
  }

  configure(config) {
    this.HOST = config.graphql_server_endpoint;
  }

  initialize(config) {
    if (this.initialized) {
      console.warn('APPOLO client can only be initialized once.');
      return;
    }

    this.configure(config);

    const httpLink = new HttpLink({
      uri: this.HOST
    });
    const link = ApolloLink.from([httpLink]);

    const authLink = setContext((_, { headers }) => {
      const token = Cookies.get('accessToken');

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        }
      };
    });

    this.apolloClient = new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache()
    });

    this.initialized = true;
  }
}

// Singleton
const apollo = new APOLLO();

export default apollo;
