import { createSlice } from '@reduxjs/toolkit';
import * as lobbyAPI from '../api/lobby/lobbyApiHandler';

const initialState = {
  isMTGamesTokenLoaded: false,
  MTGamesLobbyToken: null,
};

const slice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    getMTGamesLobbyToken(state, action) {
      state.MTGamesLobbyToken = action.payload;
      state.isMTGamesTokenLoaded = true;
    },
  }
});

export const { reducer } = slice;

export const loadMTGamesLobbyToken = () => async (dispatch) => {
  const { token } = await lobbyAPI.loadMTGamesLobbyToken();

  dispatch(slice.actions.getMTGamesLobbyToken(token));
};

export default slice;
