import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation Login($model: LoginProfileModelInput) {
        login(model: $model) {
            id
            token
            refreshToken
        }
    }
`;

export const ADD = gql`
    mutation Add($model: AddProfileGraphQlModelInput) {
        add(model: $model) {
            id
            token
            refreshToken
        }
    }
`;
