/* eslint-disable no-tabs */
import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Logo = (props) => (
  <LogoRoot
    viewBox="0 0 113.39 113.39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fill="#929EAB"
      d="M69.2,49.54c4.79-6.38,1.78-11.98,1.78-11.98c-0.68-3.13-5.06-9.46-5.06-9.46
				c-3.83-5.09-9.38-16.28-9.38-16.29v0l0,0l0,0v0c0,0-5.54,11.2-9.38,16.29c0,0-4.38,6.33-5.06,9.46c0,0-3.01,5.6,1.78,11.98
				c0,0,8.41,10.91,9.58,23.85c0,0,0.48,6.91-0.85,9.96c0,0-2.02,3.86-1.58,5.79c0,0,0.21,0.92,0.72,2.4l4.79,10.02v0v0l0,0v0
				l4.79-10.02c0.51-1.48,0.71-2.4,0.71-2.4c0.44-1.93-1.57-5.79-1.57-5.79c-1.33-3.05-0.86-9.96-0.86-9.96
				C60.78,60.46,69.2,49.54,69.2,49.54z"
    />
    <path
      fill="#929EAB"
      d="M82.76,84.33c-2.33-4.92-7.62-3.61-7.62-3.61c-6.37,2.41-1.9,6.5-1.9,6.5c4.59,2.8,5.35-1.78,5.35-1.78
				s3.52,3.32-1.1,4.55c0,0-11.01,0.81-7.77-7.57c0,0,1.98-3.84,6.98-4.18c0,0,5.45-0.52,10.67,5.87c0,0,11.06,0.95,17.96-8.12
				c0,0-7.78,2.28-17.07-2.22c0,0,20.54,1.86,25.13-15.31c0,0-4.67,7.25-19.51,8.25c0,0,21.85-7.84,18.18-25.1
				c0,0-8.64,10.23-26.48,17.65c0,0-22.53,10.47-22.36,22.4c0,0-0.09,12.96,12.61,12.9C75.82,94.56,87.26,93.84,82.76,84.33z"
    />
    <path
      fill="#929EAB"
      d="M30.62,84.33c2.33-4.92,7.62-3.61,7.62-3.61c6.37,2.41,1.9,6.5,1.9,6.5c-4.59,2.8-5.35-1.78-5.35-1.78
				s-3.52,3.32,1.1,4.55c0,0,11.01,0.81,7.77-7.57c0,0-1.98-3.84-6.98-4.18c0,0-5.45-0.52-10.67,5.87c0,0-11.06,0.95-17.96-8.12
				c0,0,7.78,2.28,17.07-2.22c0,0-20.54,1.86-25.13-15.31c0,0,4.67,7.25,19.51,8.25c0,0-21.85-7.84-18.18-25.1
				c0,0,8.64,10.23,26.48,17.65c0,0,22.53,10.47,22.36,22.4c0,0,0.09,12.96-12.61,12.9C37.57,94.56,26.12,93.84,30.62,84.33z"
    />

  </LogoRoot>
);

export default Logo;
