import { createSlice } from '@reduxjs/toolkit';
// import * as historyAPI from '../api/history/historyApiHandler';
import { historyAPI } from '../__fakeApi__/historyApi';

const initialState = {
  isHistoriesLoaded: false,
  history: {},
  histories: [],
  historiesTotalCount: 0,
  weeks: []
};

const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    getHistories(state, action) {
      const { total, entries } = action.payload;

      state.histories = entries;
      state.historiesTotalCount = total;
      state.isHistoriesLoaded = true;
    },

    getHistory(state, action) {
      state.history = action.payload;
    },

    getWeeks(state, action) {
      state.weeks = action.payload;
    },
  }
});

export const { reducer } = slice;

export const getHistories = (week, page, limit = 10) => async (dispatch) => {
  const histories = await historyAPI.getHistories(week, page * limit, limit);
  const { meta: { total }, entries } = histories;

  dispatch(slice.actions.getHistories({ total, entries }));
};

export const getHistory = (id) => async (dispatch) => {
  const history = await historyAPI.getHistory(id);

  dispatch(slice.actions.getHistory(history));
};

export const getWeeks = (id) => async (dispatch) => {
  const weeks = await historyAPI.getWeeks(id);

  dispatch(slice.actions.getWeeks(weeks));
};

export default slice;
