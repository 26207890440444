/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({});

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `Bearer ${token}`;
};

const interceptors = (instance) => ({ logout, updateToken }) => {
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  instance.interceptors.request.use((config) => {
    attachTokenToRequest(config, Cookies.get('accessToken'));

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then((token) => {
            attachTokenToRequest(originalRequest, token);
            return axios(originalRequest);
          }).catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = Cookies.get('refreshToken');

        return new Promise((resolve, reject) => {
          updateToken({ refreshToken })
            .then(({ data, success }) => {
              if (!success) throw Error();
              Cookies.set('accessToken', data.token);
              Cookies.set('refreshToken', data.refreshToken);
              attachTokenToRequest(originalRequest, data.accessToken);
              processQueue(null, data.accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              logout();
              reject(err);
            })
            .finally(() => { isRefreshing = false; });
        });
      }
      return Promise.reject(error);
    }
  );
};

export const enableInterceptors = interceptors(axiosInstance);

export default axiosInstance;
