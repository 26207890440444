import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
        id
        username
        isActive
        email
        isEmailVerified
        emailVerificationDate
        phoneNumber
        isPhoneVerified
        phoneVerificationDate
        firstName
        surname
        country
        city
        address
        defaultCurrencyCode
        createdDate
    }
  }
`;

export const GET_BALANCE = gql`
    query Balance($model: GetBalanceModelInput) {
        balance(model: $model) {
            totalBalance
        }
    }
`;
