import { combineReducers } from '@reduxjs/toolkit';
import { reducer as historyReducer } from '../slices/history';
import { reducer as lobbyReducer } from '../slices/lobby';

const rootReducer = combineReducers({
  history: historyReducer,
  lobby: lobbyReducer,
});

export default rootReducer;
